.root {
	--button-h: var(--button-height, 42px);

	composes: taptic from '/src/assets/styles/composes.css';
	display: inline-block;
	box-sizing: border-box;
	width: var(--button-width, auto);
	height: var(--button-h);
	border: 1px solid var(--button-border-color, transparent);
	border-radius: var(--unit);
	padding: 0 var(--unit-x4);
	vertical-align: middle;
	background-color: var(--button-bg, transparent);
	color: var(--button-color, #fff);
	font-weight: var(--button-fw, 700);
	font-size: var(--text-s);
	line-height: calc(var(--button-h) - 2px);
	text-align: center;
	text-decoration: none;
	text-transform: var(--button-tt, uppercase);
	white-space: nowrap;
	cursor: pointer;
	user-select: none;
	transition-timing-function: ease-in-out;
	transition-duration: 0.1s;
	transition-property: color, background-color, border-color, transform;

	&:focus {
		outline: none;
		transform: scale(1);
	}

	&:active {
		box-shadow: none;
		transform: scale(0.98);
	}

	&[disabled],
	&:disabled {
		background-color: var(--button-disabled-background, rgba(0, 0, 0, 0.3));
		cursor: not-allowed;
		transform: scale(1);

		&:focus {
			box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4);
		}
	}

	& * {
		pointer-events: none;
	}
}

.content {
	width: 100%;
	height: 100%;
}

.rounded {
	border-color: var(--button-border-color, var(--button-bg));
	border-radius: calc(var(--button-h) / 2);
}

.action {
	background-color: var(--button-bg, rgb(var(--color-action)));
	color: var(--button-color, #fff);

	&:focus {
		box-shadow: 0 0 0 2px rgba(var(--button-color, var(--color-action)), 0.4);
	}
}

.sark {
	background-color: var(--button-bg, rgb(var(--color-sark)));
	color: var(--button-bg, #fff);
}

.black {
	background-color: var(--button-bg, #000);
	color: var(--button-bg, #fff);

	&:focus {
		box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4);
	}
}

.white {
	background-color: var(--button-bg, #fff);
	color: var(--button-bg, #000);
}

.cobalt {
	background-color: rgb(var(--kit-colors-cobalt-800));
}

.link {
	border-color: var(--button-border-color, transparent);
	background-color: var(--button-bg, transparent);
}

.link.action {
	color: rgb(var(--color-action));
}

.link.black {
	background-color: var(--button-bg, transparent);
	color: var(--button-color, #000);
}

.flat {
	border-color: var(--button-border-color, transparent);
}

.flat.sark {
	background-color: var(--button-bg, rgba(var(--color-sark), 0.1));
	color: var(--button-color, rgb(var(--color-sark)));
}

.flat.cobalt {
	border: 1px solid rgb(var(--kit-colors-charcoal-400));
	background-color: transparent;
	color: var(--button-color, rgb(var(--kit-colors-charcoal-800)));
}

.flat.black {
	background-color: var(--button-bg, rgba(0, 0, 0, 0.05));
}

.flat.action {
	background-color: var(--button-bg, rgba(var(--color-action), 0.05));
}

.flat.white {
	background-color: var(--button-bg, rgba(255, 255, 255, 0.1));
	color: var(--button-color, #fff);
}

.flat.lunar {
	background-color: var(--button-bg, rgba(var(--color-lunar), 0.1));
	color: var(--button-color, rgb(var(--color-lunar)));
}

.loading {
	cursor: wait;
	pointer-events: none;
	opacity: 0.6;
}

.spinner {
	position: absolute;
	top: calc(50% - 11px);
	left: calc(50% - 11px);
	display: block;
	width: 16px;
	height: 16px;
	border: 3px solid #fff;
	border-left-color: transparent;
	border-radius: 50%;
	animation: spin 1s linear infinite;
}

.loading .content {
	opacity: 0;
}

.shallow {
	border-width: 2px;
	background-color: unset;
}

.shallow.black {
	border-color: #000;
	color: rgb(var(--color-black));
}

.fill {
	width: 100%;
	text-align: center;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
