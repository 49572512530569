.root {
	position: relative;
	display: flex;
	border: 1px solid rgb(var(--kit-colors-charcoal-100));
	border-radius: 8px;
	padding: 10px 14px 16px;
	flex-direction: column;
	row-gap: 14px;
}

.disabled {
	cursor: unset;
}

.loading {
	position: relative;
	pointer-events: none;

	&::before {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		content: '';
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.4);
	}
}

.info {
	display: flex;
	margin-top: 8px;
	flex-direction: column;
}

.titleLink {
	text-decoration: none;
	display: flex;
	align-items: center;
	gap: 8px;
}

.progress {
	display: flex;
	align-items: center;
	column-gap: 6px;
}

.actions {
	display: flex;
	flex-direction: column;
	row-gap: 8px;
	align-items: flex-start;
}

.actionsRow {
	display: flex;
	gap: 8px;
	text-wrap: nowrap;
}

.status {
	text-transform: capitalize;
}

.icon {
	margin-top: 3px;
	color: rgb(var(--kit-colors-tangerine-800));
}

.badge {
	background-color: rgb(var(--kit-colors-grass));
	color: rgb(var(--kit-colors-pure));
	text-transform: uppercase;
	padding: 2px 8px;
	border-radius: 8px;
	font-size: var(--text-xs);
}
